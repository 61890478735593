<template>
  <div style="margin: -15px -15px">
    <a-spin :spinning="spinning">
    <div class="doctorList">
      <div class="doctorListTop">
        <div class="doctorListTop-title">
          <div>
            <img :src="this.$route.query.pic" style="width: 50px;height: 50px" alt="">
          </div>
          <div class="font">
            <span>{{ this.$route.query.title }}</span>
          </div>
        </div>
        <div>
          <a-tabs v-model="menuItem" @change="()=>{this.getList()}">
            <a-tab-pane key="case" tab="已上传病例">
            </a-tab-pane>
            <a-tab-pane key="template" tab="已添加模版">
            </a-tab-pane>
          </a-tabs>
<!--          <a-menu v-model="menuItem" mode="horizontal">-->
<!--            <a-menu-item key="1">-->
<!--              已上传病例-->
<!--            </a-menu-item>-->

<!--            <a-menu-item key="2">-->
<!--              已添加模版-->
<!--            </a-menu-item>-->
<!--          </a-menu>-->
        </div>
      </div>
      <div class="doctorListContent">
        <div class="doctorListContent-top">
          <div style="width: 90%;display: flex">
            <span style="margin-top: 5px;">手机号:</span>
            <a-input placeholder="请输入" style="width: 300px;margin-left: 5px;margin-right: 20px" v-model="tel"/>
            <a-button @click="lookBtn" type="primary">查询</a-button>
          </div>
          <div>
            <a-button @click="ExportBulk"><a-icon style="color: #00CC33" type="file-excel" />批量导出</a-button>
          </div>
        </div>
        <a-table :rowKey='record=>record.memberId' :pagination="false" style="width: 95%;margin: auto" :columns="columns.filter((col,num)=>{if(col.show){return col}})" :data-source="data">
          <span slot="code" slot-scope="text, record">
            <a @click="openModel(record.memberId)">
              {{record.doctorNum === null || record.doctorNum === '' || record.doctorNum === undefined ? '无':record.doctorNum}}</a>
          </span>
        </a-table>
        <!--      分页-->
        <div style="width: 100%">
          <div class="pageClass">
            <a-pagination
                :current="pageNo"
                @change="pageChange"
                :total="total"
                show-quick-jumper />
          </div>
        </div>
      </div>
    </div>
<!--------------------------------------------------     对话框     --------------------------------->
      <ListPackaging></ListPackaging>
      <a-drawer
        title="修改"
        placement="right"
        :closable="false"
        :visible="addVisible"
        @close="addClose"
        width="500"
    >
      <div class="addForms">
        <a-form-model
            ref="addRuleForm"
            :model="addForm"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol">
          <a-form-model-item label="身份" prop="type">
            <a-select
                show-search
                option-filter-prop="children"
                @change="selectNumberType"
                placeholder="请选择"
                v-model="addForm.type"
                style="width: 300px">
              <a-select-option value="1001">
                医生
              </a-select-option>
              <a-select-option value="1002">
                护士
              </a-select-option>
              <a-select-option value="1003">
                医技
              </a-select-option>
              <a-select-option value="1004">
                药师
              </a-select-option>
              <a-select-option value="1005">
                学生
              </a-select-option>
              <a-select-option value="1006">
                其他医务从业者
              </a-select-option>
              <a-select-option value="1007">
                非医务从业者
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="姓名" prop="name">
            <a-input style="width: 300px" v-model="addForm.name" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item label="手机号" prop="tel">
            <a-input style="width: 300px" v-model="addForm.tel" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item
              label="省份/城市" prop="province">
            <a-cascader
                style="width: 300px"
                v-model="addForm.province"
                :options="hospitaloPtions"
                placeholder="请选择"
                @change="provinceChange"
            />
          </a-form-model-item>
          <a-form-model-item
              v-if="addForm.type === '1001' || addForm.type === '1002' || addForm.type === '1003' ||addForm.type === '1004'"
              label="医院" prop="hospitalName">
            <a-select
                @popupScroll="popupScrollHos"
                @search="selectHos"
                style="width: 300px"
                v-model="addForm.hospitalName"
                placeholder="请选择"
                @change="hospitalSeleChange">
              <a-select-option
                  v-for="item in hospitalNameList"
                  :value="item.name">
                {{item.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
              v-if="addForm.type === '1001' || addForm.type === '1002' || addForm.type === '1003' ||addForm.type === '1004'"
              label="科室" prop="hospitalOffices">
            <a-cascader
                style="width: 300px"
                v-model="addForm.hospitalOffices"
                :options="hospitalOfficesList"
                placeholder="请选择"
                @change="officeChange" />
          </a-form-model-item>
          <a-form-model-item
              v-if="addForm.type === '1001' || addForm.type === '1002' || addForm.type === '1003' ||addForm.type === '1004'"
              label="职称" prop="jobTitle">
            <a-select
                style="width: 300px"
                v-model="addForm.jobTitle"
                placeholder="请选择"
                @change="jobTitleChange">
              <a-select-option
                  v-for="item in jobTitleList"
                  :value="item.job_title">
                {{item.job_title}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <!-------------------------------1005------------------------------------------->
          <a-form-model-item v-if="addForm.type === '1005'" label="学院" prop="school">
            <a-select
                @popupScroll="popupScrollSchool"
                @search="selectSchool"
                style="width: 300px"
                v-model="addForm.school"
                placeholder="请选择"
                @change="schoolChange">
              <a-select-option
                  v-for="item in schoolListData"
                  :value="item.name">
                {{item.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item v-if="addForm.type === '1005'" label="学历" prop="education">
            <a-select style="width: 300px" placeholder="请选择" v-model="addForm.education"
                      @change="educationChange">
              <a-select-option
                  v-for="item in educationList"
                  :value="item.education">
                {{item.education}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item v-if="addForm.type === '1005'" label="专业" prop="specialty">
            <a-input style="width: 300px" v-model="addForm.specialty" placeholder="请输入" />
          </a-form-model-item>
          <!-------------------------------1006  ~  1007------------------------------------------->
          <a-form-model-item
              v-if="addForm.type === '1006' || addForm.type === '1007'"
              label="单位" prop="workUnit">
            <a-input style="width: 300px" v-model="addForm.workUnit" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item
              v-if="addForm.type === '1006' || addForm.type === '1007'"
              label="职务" prop="post">
            <a-input  style="width: 300px" v-model="addForm.post" placeholder="请输入" />
          </a-form-model-item>

          <!--      新增时共有的        -->
          <a-form-model-item label="性别">
            <a-select style="width: 300px" placeholder="请选择" v-model="addForm.sex">
              <a-select-option
                  v-for="item in sexList"
                  :value="item.value">
                {{item.sex}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
              label="邮箱">
            <a-input style="width: 300px" v-model="addForm.email" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item
              label="资格证书编码">
            <a-input style="width: 300px" v-model="addForm.doctorNum" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item
              label="生日">
            <a-date-picker :allowClear="false" @change="changeBirthday" placeholder="请选择" style="width: 300px" v-model="addForm.birthday" />
          </a-form-model-item>
          <a-form-model-item
              label="认证状态">
            <span v-if="addForm.certStatus === 'UNCERTIFIED'">未认证</span>
            <a style="color: orange" @click="inReview" v-if="addForm.certStatus === 'IN_REVIEW'">
              待审核，点击审核
            </a>
<!--            <a @click="certified" v-if="addForm.certStatus === 'CERTIFIED'">已认证</a>-->
            <a v-if="addForm.certStatus === 'CERTIFIED'" @click="openNotification('topLeft')">
              已认证
            </a>
          </a-form-model-item>
          <a-form-model-item
              label="注册渠道">
            {{addForm.registerChannel === null || addForm.registerChannel === '' || addForm.registerChannel === undefined ? '未知':addForm.registerChannel}}
<!--            <a-input :disabled="true" style="width: 300px;margin-bottom: 20px" v-model="addForm.registerChannel" placeholder="请输入" />-->
          </a-form-model-item>
        </a-form-model>
      </div>
      <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click="addClose">
          取消
        </a-button>
        <a-button type="primary" @click="onSubmit">
          确认
        </a-button>
      </div>
    </a-drawer>
      <a-modal :maskClosable="false" v-model="visible" title="认证材料" :footer="null" >
          <div style="margin: 20px;" v-if="auditForm">
            <img class="auditPic" :src="auditForm.employee_card_url" alt="" v-if="auditForm.employee_card_url">
            <img class="auditPic" :src="auditForm.practice_license_url" alt="" v-if="auditForm.practice_license_url">
            <img class="auditPic" :src="auditForm.nvq_url" alt="" v-if="auditForm.nvq_url">
          </div>
        <div
            v-if="isShow"
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
        >
          <a-button  @click="closeModel">
            取消
          </a-button>
          <a-button style="margin-left: 10px" type="primary" @click="handleOk">
            确认
          </a-button>
        </div>
      </a-modal>
    </a-spin>

    <!-- 底部   -->
    <div style="height: 100px"></div>
    <div style="width:100%;height:50px;position: fixed;bottom: 0px;background-color: #f0f2f5;border-bottom: 1px solid #fff">
      <div
          style="width: 80%;display: flex;justify-content: right;margin-top: 10px">
        <a-button @click="returnBtn">返回上一层</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import {getTitle} from "@/service/dataDictionary_api";
import {getHospital} from "@/service/hospital_api";
import {getSchool} from "@/service/school_api";
import {getLoginDepartment} from "@/service/dataCleaned_api";
import ListPackaging from '@/views/content/case/ListPackaging'
const columns = [
  {
    title: '姓名',
    dataIndex: 'name',
    key: 'name',
    show:true
  },
  {
    title: '手机号',
    dataIndex: 'tel',
    key: 'tel',
    show:true
  },
  {
    title: '医院',
    dataIndex: 'hospitalName',
    key: 'hospitalName',
    show:true
  },
  {
    title: '上传病例数',
    dataIndex: 'upSum',
    key: 'upSum',
    show:true
  },
  {
    title: '资格证号',
    key: 'doctorNum',
    dataIndex: 'doctorNum',
    scopedSlots: { customRender: 'code' },
    show:true
  },
  // {
  //   title: '时间',
  //   dataIndex: 'time',
  //   key: 'time',
  // },
  // {
  //   title: 'Action',
  //   key: 'action',
  //   scopedSlots: { customRender: 'action' },
  // },
];

const data = [];
import City from '@/assets/city.json'
import {putMember, getCasePatientTemp, getPatientTemp} from "@/service/MedicalConference_y";
import moment from "moment";
import {getAuditId, getCaseExportBulk} from "@/service/memberImport_api";
export default {
  components:{
    ListPackaging,
  },
  computed:{
    listBox(){
      return this.$store.state.listDialogBox
    }
  },
  data(){
    return{
      writeBackImg:require('@/assets/img_3.png'),
      auditForm:{

      },
      visible:false,
      spinning:false,
      City:City,
      menuItem:'case',
      data,
      columns,
      tel:'',
      addVisible:false,
      addForm:{
        type:'1001',
        name:'',
        tel:'',
        province:undefined,
        city:'',
        hospitalName:undefined,
        hospitalOffices:undefined,
        jobTitle:undefined,
        school:undefined,
        education:undefined,
        specialty:'',
        workUnit:'',
        post:'',
        doctorNum:'',
        sex:undefined,
        email:'',
        birthday:'',
        certStatus:'未认证',
        registerChannel: '后台添加',
      },
      rules:{},
      //1001-1004
      rules1:{
        type: [{ required: true, message: '请选择', trigger: 'blur' }],
        name: [{ required: true, message: '请输入', trigger: 'change' }],
        tel: [{ required: true, message: '请输入', trigger: 'change' }],
        province: [{ required: true, message: '请选择', trigger: 'change' }],
        hospitalName: [{ required: true, message: '请选择', trigger: 'change' }],
        hospitalOffices: [{ required: true, message: '请选择', trigger: 'change' }],
        jobTitle: [{ required: true, message: '请选择', trigger: 'change' }],
      },
      //1005
      rules2:{
        type: [{ required: true, message: '请选择', trigger: 'blur' }],
        name: [{ required: true, message: '请输入', trigger: 'change' }],
        tel: [{ required: true, message: '请输入', trigger: 'change' }],
        province: [{ required: true, message: '请选择', trigger: 'change' }],
        school: [{ required: true, message: '请选择', trigger: 'change' }],
        education: [{ required: true, message: '请选择', trigger: 'change' }],
        specialty: [{ required: true, message: '请输入', trigger: 'change' }],
      },
      //1006-1007
      rules3:{
        type: [{ required: true, message: '请选择', trigger: 'blur' }],
        name: [{ required: true, message: '请输入', trigger: 'change' }],
        tel: [{ required: true, message: '请输入', trigger: 'change' }],
        province: [{ required: true, message: '请选择', trigger: 'change' }],
        workUnit: [{ required: true, message: '请输入', trigger: 'change' }],
        post: [{ required: true, message: '请输入', trigger: 'change' }],
      },
      labelCol: { span: 5 },
      wrapperCol: { span: 14 },
      hospitaloPtions: [],
      hospitalNameList:[],
      hospitalNameList_pageNo:1,
      schoolNameList_pageNo:1,
      hosTitle:'',
      schTitle:'',
      hospitalOfficesList:[],
      jobTitleList:[],
      schoolListData:[],
      educationList:[
        {
          id:'1',
          education:'初中'
        },
        {
          id:'2',
          education:'高中'
        },
        {
          id:'3',
          education:'专科'
        },
        {
          id:'4',
          education:'本科'
        },
        {
          id:'5',
          education:'研究生'
        },
      ],
      sexList:[
        {
          id:1,
          sex:'男',
          value:1,
        },{
          id:2,
          sex:'女',
          value:2,
        },{
          id:3,
          sex:'未知',
          value:0,
        },
      ],
      reg:RegExp(/-/),
      pageNo:1,
      total:0,
      isShow:false,
    }
  },
  created() {
    this.getList()
    this.getHospitalOfficesList()
    this.getJobTitleList(this.addForm.type,1)
    this.$store.dispatch('setManageHeaderTitle', "")
  },
  watch:{
    menuItem(newVal, oldVal) {
      this.columns.forEach(value => {
        if (value.title==='上传病例数'){
          value.show=newVal==='case'?true:false
        }
      })
    },

    //监听对话框处于关闭状态下，将选择框置空
    listBoxIsShow(newVal){
      if(!newVal){
        this.getList()
      }
    }
  },
  mounted() {
    this.rules = this.rules1
    this.getPCH()
  },
  methods:{
    async getList() {
      this.spinning = true
      let data = {
        tel:this.tel,
        templateId:this.$route.query.templateId,
        pageNo:this.pageNo,
        tpageNumel:10,
      }
      const response =this.menuItem==='case'? await getCasePatientTemp(data):await getPatientTemp(data)
      if(response.code === 0){
        this.data = response.data
        this.total = response.count
      }else {
        this.$message.warning(response.message)
      }
      this.spinning = false
    },
    //获取省份城市医院
    getPCH(){
      let province = []
      let hospitalName = []
      this.City.forEach((item,index)=>{
        let city = []
        province.push({
          value:item.name,
          label:item.name,
          children:city
        })
        item.cityList.forEach((list)=>{
          city.push({
            value:list.name,
            label:list.name,
            loading:false,
          })
        })
        province[index].children = city
      })
      this.hospitaloPtions = province
      this.schoolPtions = province
    },
    openModel(memberId){
      console.log(memberId)
      this.user_id = memberId
      this.hospitalNameList = []
      this.schoolListData = []

      this.listBox.memberId = memberId
      //打开对话框 -- ListPackaging
      this.listBox.isShow = true

      // this.addVisible = true
      // this.getListOne(memberId)
    },
    addClose(){
      this.addVisible = false
      this.hospitalNameList = []
      this.schoolListData = []
      this.hospitalNameList_pageNo = 1
      this.schoolNameList_pageNo = 1
      this.$refs.addRuleForm.resetFields()
      let form = this.addForm
      form.birthday = undefined
      form.sex = undefined
      form.doctorNum = ''
      form.email = ''
      form.certStatus = '未认证'
      form.registerChannel = '后台添加'
    },
    selectNumberType(){
      //其他医务从业者--医务从业者不需要选择省份城市
      if(this.addForm.type !== '1006' || this.addForm.type !== '1007'){
        this.getJobTitleList(this.addForm.type,1)
      }
      //选择身份时切换rules校验
      switch (this.addForm.type){
        case '1001':{
          this.rules = this.rules1
          break;
        }
        case '1002':{
          this.rules = this.rules1
          break;
        }
        case '1003':{
          this.rules = this.rules1
          break;
        }
        case '1004':{
          this.rules = this.rules1
          break;
        }
        case '1005':{
          this.rules = this.rules2
          break;
        }
        case '1006':{
          this.rules = this.rules3
          break;
        }
        case '1007':{
          this.rules = this.rules3
          break;
        }
        default:{
          break;
        }
      }
    },
    provinceChange(value) {
      this.hospitalNameList = []
      this.schoolListData = []
      this.hospitalNameList_pageNo = 1
      this.schoolNameList_pageNo = 1
      if(this.addForm.type === '1001' ||
          this.addForm.type === '1002' ||
          this.addForm.type === '1003' ||
          this.addForm.type === '1004'
      ){
        let data = {
          province:value[0],
          city:value[1],
          pagenum:this.hospitalNameList_pageNo
        }
        this.getHosNameList(data)
      }else if(this.addForm.type === '1005'){
        let data = {
          province:value[0],
          city:value[1],
          pagenum:this.schoolNameList_pageNo
        }
        this.getschoolList(data)
      }
      // else if(this.addForm.type === '1006' ||
      //     this.addForm.type === '1007'){
      //
      // }
    },
    //获取职称列表
    async getJobTitleList(identify,pageNum,title) {
      //type:identify,pageNum,title
      const response = await getTitle(identify,pageNum,title)
      if (response.code === 0) {
        this.jobTitleList = response.data.row
      }
    },
    //获取医院列表
    async getHosNameList(data) {
      const response = await getHospital(data)
      if(response.code === 0){
        // this.hospitalNameList = response.data.rows
        let list  = response.data.rows
        for (let i=0;i<list.length;i++){
          this.hospitalNameList.push({
            id: list[i].id,
            name: list[i].name,
          })
        }
      }
    },
    //滚动条监听
    popupScrollHos(e){
      const {target} = e
      const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
      const clientHeight = target.clientHeight //滑动最低高度
      if(scrollHeight < clientHeight + 2){
        this.hospitalNameList_pageNo++
        let data = {
          province:this.addForm.province[0],
          city:this.addForm.province[1],
          pagenum:this.hospitalNameList_pageNo,
          name:this.hosTitle
        }
        this.getHosNameList(data)
      }
    },
    selectHos(value) {
      //先清空，防止鼠标滑动时添加的数据输入时添加的数据重复
      this.hospitalNameList = []
      this.hosTitle = value
      let data = {
        province:this.addForm.province[0],
        city:this.addForm.province[1],
        name:this.hosTitle
      }
      this.getHosNameList(data)
    },
    //获取学院列表
    async getschoolList(data) {
      const response = await getSchool(data)
      if(response.code === 0){
        // this.schoolListData = response.data.rows
        let list  = response.data.rows
        for (let i=0;i<list.length;i++){
          this.schoolListData.push({
            id: list[i].id,
            name: list[i].name,
          })
        }
      }
    },
    //滚动条监听
    popupScrollSchool(e){
      const {target} = e
      const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
      const clientHeight = target.clientHeight //滑动最低高度
      if(scrollHeight < clientHeight + 2){
        this.schoolNameList_pageNo++
        let data = {
          province:this.addForm.province[0],
          city:this.addForm.province[1],
          pagenum:this.schoolNameList_pageNo,
          name:this.schTitle
        }
        this.getschoolList(data)
      }
    },
    selectSchool(value) {
      //先清空，防止鼠标滑动时添加的数据输入时添加的数据重复
      this.schoolListData = []
      this.hosTitle = value
      let data = {
        province:this.addForm.province[0],
        city:this.addForm.province[1],
        name:this.schTitle
      }
      this.getschoolList(data)
    },
    hospitalSeleChange(){

    },
    //获取科室列表
    async getHospitalOfficesList() {
      const response = await getLoginDepartment()
      if(response.code === 0){
        let oneOff = []
        let list = response.data.wySy
        list.forEach((item,index)=>{
          let twoOff = []
          oneOff.push({
            value:item.title,
            label:item.title,
            children:twoOff
          })
          item.zoffices.forEach((lists)=>{
            twoOff.push({
              value:lists.title,
              label:lists.title,
            })
          })
          oneOff[index].children = twoOff
        })
        this.hospitalOfficesList = oneOff
      }
    },
    jobTitleChange(value) {
      console.log(value);
    },
    officeChange(value){
      console.log(value);
    },
    schoolChange(){

    },
    educationChange(){

    },
    onSubmit(){
      this.$refs.addRuleForm.validate(valid => {
        if (valid) {
          let form = this.addForm
          let data = {};
          switch (form.type){
              case '1001':{
                data = {
                  "type": form.type,
                  "name": form.name,
                  "tel": form.tel,

                  "province": form.province[0],
                  "city": form.province[1],

                  "hospitalName": form.hospitalName,
                  "hospitalOffices": form.hospitalOffices[0]+'-'+form.hospitalOffices[1],
                  "jobTitle": form.jobTitle,

                  "doctorNum":form.doctorNum,
                  "sex": form.sex,
                  "email": form.email,
                  // "birthday": moment(form.birthday).format('YYYY-MM-DD'),
                  "birthday": form.birthday,
                  "certStatus": form.certStatus,
                  "registerChannel": form.registerChannel,
                }
                break;
              }
              case '1002':{
                data = {
                  "type": form.type,
                  "name": form.name,
                  "tel": form.tel,
                  "hospitalName": form.hospitalName,
                  "hospitalOffices": form.hospitalOffices[0]+'-'+form.hospitalOffices[1],
                  "jobTitle": form.jobTitle,

                  "province": form.province[0],
                  "city": form.province[1],

                  "doctorNum":form.doctorNum,
                  "sex": form.sex,
                  "email": form.email,
                  // "birthday": moment(form.birthday).format('YYYY-MM-DD'),
                  "birthday": form.birthday,
                  "certStatus": form.certStatus,
                  "registerChannel": form.registerChannel,
                }
                break;
              }
              case '1003':{
                data = {
                  "type": form.type,
                  "name": form.name,
                  "tel": form.tel,
                  "hospitalName": form.hospitalName,
                  "hospitalOffices": form.hospitalOffices[0]+'-'+form.hospitalOffices[1],
                  "jobTitle": form.jobTitle,

                  "province": form.province[0],
                  "city": form.province[1],

                  "doctorNum":form.doctorNum,
                  "sex": form.sex,
                  "email": form.email,
                  // "birthday": moment(form.birthday).format('YYYY-MM-DD'),
                  "birthday": form.birthday,
                  "certStatus": form.certStatus,
                  "registerChannel": form.registerChannel,
                }
                break;
              }
              case '1004':{
                data = {
                  "type": form.type,
                  "name": form.name,
                  "tel": form.tel,
                  "hospitalName": form.hospitalName,
                  "hospitalOffices": form.hospitalOffices[0]+'-'+form.hospitalOffices[1],
                  "jobTitle": form.jobTitle,

                  "province": form.province[0],
                  "city": form.province[1],

                  "doctorNum":form.doctorNum,
                  "sex": form.sex,
                  "email": form.email,
                  // "birthday": moment(form.birthday).format('YYYY-MM-DD'),
                  "birthday": form.birthday,
                  "certStatus": form.certStatus,
                  "registerChannel": form.registerChannel,
                }
                break;
              }
              case '1005':{
                data = {
                  "type": form.type,
                  "name": form.name,
                  "tel": form.tel,
                  "school": form.school,
                  "education": form.education,
                  "specialty": form.specialty,

                  "province": form.province[0],
                  "city": form.province[1],

                  "doctorNum":form.doctorNum,
                  "sex": form.sex,
                  "email": form.email,
                  // "birthday": moment(form.birthday).format('YYYY-MM-DD'),
                  "birthday": form.birthday,
                  "certStatus": form.certStatus,
                  "registerChannel": form.registerChannel,
                }
                break;
              }
              case '1006':{
                data = {
                  "type": form.type,
                  "name": form.name,
                  "tel": form.tel,
                  "workUnit": form.workUnit,
                  "post": form.post,

                  "province": form.province[0],
                  "city": form.province[1],

                  "doctorNum":form.doctorNum,
                  "sex": form.sex,
                  "email": form.email,
                  // "birthday": moment(form.birthday).format('YYYY-MM-DD'),
                  "birthday": form.birthday,
                  "certStatus": form.certStatus,
                  "registerChannel": form.registerChannel,
                }
                break;
              }
              case '1007':{
                data = {
                  "type": form.type,
                  "name": form.name,
                  "tel": form.tel,
                  "workUnit": form.workUnit,
                  "post": form.post,

                  "province": form.province[0],
                  "city": form.province[1],

                  "doctorNum":form.doctorNum,
                  "sex": form.sex,
                  "email": form.email,
                  "birthday": form.birthday,
                  // "birthday": moment(form.birthday).format('YYYY-MM-DD'),
                  "certStatus": form.certStatus,
                  "registerChannel": form.registerChannel,
                }
                break;
              }
              default:{
                break;
              }
            }
          this.putList(this.user_id,data)
          this.addVisible = false
          //清空
          this.hospitalNameList = []
          this.schoolListData = []

          this.hospitalNameList_pageNo = 1
          this.schoolNameList_pageNo = 1
          this.user_id = ''
          this.$refs.addRuleForm.resetFields()

          form.birthday = undefined
          form.sex = undefined
          form.doctorNum = ''
          form.email = ''
          form.certStatus = '未认证'
          form.registerChannel = '后台添加'
        } else {
          return false;
        }
      });
    },
    //  修改
    async putList(id,data) {
      const response = await putMember(id,data)
      if(response.code === 0){
        this.$message.success("修改成功~")
        //  刷新列表
        await this.getList()
      }else {
        this.$message.warning(response.message)
      }
    },
    // //获取单个
    // async getListOne(memberId) {
    //   let data = {
    //     id:memberId,
    //   }
    //   const response = await getMemberList(data)
    //   if(response.code === 0){
    //     //  赋值
    //     this.addForm = response.data[0]
    //     this.addForm.province = [response.data[0].province,response.data[0].city]
    //     if(this.addForm.hospitalOffices){
    //       this.addForm.hospitalOffices = response.data[0].hospitalOffices.split('-')
    //     }
    //
    //     //  加载医院与学院
    //     if(this.addForm.province){
    //
    //       let data = {
    //         province:this.addForm.province[0],
    //         city:this.addForm.province[1],
    //         pagenum:this.hospitalNameList_pageNo
    //       }
    //       this.getHosNameList(data)
    //       let data2 = {
    //         province:this.addForm.province[0],
    //         city:this.addForm.province[1],
    //         pagenum:this.schoolNameList_pageNo
    //       }
    //       this.getschoolList(data2)
    //     }
    //   }else {
    //     this.$message.warning(response.message)
    //   }
    // },
    pageChange(pageNo){
      this.pageNo = pageNo
      this.getList()
    },
    lookBtn(){
      this.getList()
    },
    closeModel(){
      this.visible = false
    },
    handleOk(){
      this.visible = false
    },
    async certified() {
      this.isShow = false
      const Info = await getAuditId(this.user_id)
      if(Info.data){
        this.auditForm = Info.data[0]
      }
      this.visible = true
    },
    inReview(){
      // this.isShow = true
      // this.visible = true
    },
    changeBirthday(date, dateString) {
      this.addForm.birthday = moment(this.addForm.birthday).format('YYYY-MM-DD')
    },
    returnBtn(){
      window.history.back()
    },
    async openNotification(placement) {
      const Info = await getAuditId(this.user_id)
      // if (Info.data) {
      //   this.auditForm = Info.data[0]
      // }
      this.$notification.open({
        message: `认证材料`,
        description:
            <div style="height:600px;overflow-y:scroll">
              <div>
                <img src={`${Info.data[0].employee_card_url ? Info.data[0].employee_card_url:this.writeBackImg}`} style='max-width:500px;height:auto;'/>
              </div>
              <div>
                <img src={`${Info.data[0].practice_license_url ? Info.data[0].practice_license_url : this.writeBackImg}`} style='max-width:500px;height:auto;'/>
              </div>
              <div>
                <img src={`${Info.data[0].nvq_url ? Info.data[0].nvq_url : this.writeBackImg}`} style='max-width:500px;height:auto;'/>
              </div>

            </div>,
        style:{
          "width":"600px",
          "min-height":"400px"
        },
        placement,
        duration: 0,
      });
    },
    async ExportBulk() {
      let data = {
        ExelName:this.menuItem==='case'?'已上传病例':'已添加模版',
      }
      const response = await getCaseExportBulk(this.$route.query.templateId, this.menuItem,data)
    }
  }
}
</script>

<style lang="scss" >

.doctorList{
  width: 100%;
  height: 100%;
  ::v-deep .ant-tabs-bar{
    margin: 0;
  }
  .doctorListTop{
    width: 100%;
    padding-top: 20px;
    padding-left: 20px;
    line-height: 50px;
    border-bottom: 10px solid #f1f0f0;
    .doctorListTop-title{
      display: flex;
      .font{
        margin-left: 20px;
        font-size: 18px;
        font-weight: bold;
        color: #333333;
      }
    }
  }
  .doctorListContent{
    width: 100%;
    .doctorListContent-top{
      width: 95%;
      margin: auto;
      display: flex;
      height: 70px;
      padding-top: 20px;
    }
  .pageClass{
    width: 100%;
    text-align: right;
    float: right;
    margin-top: 15px;
    margin-right: 20px;
  }
  }
}
.auditPic{
  width: 90%;
  height: auto;
}
</style>
